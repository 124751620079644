'use client'
import React, {useContext, useState} from "react";
import UserContext from "@/shared/components/Layouts/UserContext";
import UndismissableModal from "@/shared/components/Modals/UndismissableModal";
import Select from "@/shared/components/SelectOption/Select";
import {nanoid} from "nanoid";
import {createClient} from "@/shared/supabase/client";

const UndismissableStoreConnection = ({active}: { active: boolean }) => {
    const supabase = createClient();
    const [region, setRegion] = useState<string>("eu");
    const handleConnect = () => {
        const state = nanoid(64);
        let url;
        if (region === "eu") {
            url = `https://sellercentral.amazon.it/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store/eu`;
        } else if (region === "na") {
            url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store/na`;
        } else {
            url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store/fe`;
        }
        window.open(url, "_self");
    };
    async function signOut() {
        const { error } = await supabase.auth.signOut(
            {
                scope: 'local' // Only closes the current session
            }
        );
        window.location.reload();
    }
    return <>{active ?
        <>
            <div className={'p-6.5'}>
                <br/>
                <ol>
                    <li>Scegli la regione del tuo account Amazon e clicca su Connetti</li>
                </ol>
            </div>

            <Select
                value={region}
                onChange={(e) => setRegion(e as string)}
                options={[
                    {
                        value: "eu",
                        label: "Europe",
                    },
                    {
                        value: "na",
                        label: "North America",
                    },
                    {
                        value: "fe",
                        label: "Japan",
                    },
                ]}
            />

            <div className="flex justify-center items-right p-6.5 gap-6">
                <button
                    type="button"
                    onClick={signOut}
                    className="rounded border border-secondary bg-secondary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                >
                    Logout
                </button>
                <button
                    type="button"
                    onClick={handleConnect}
                    className="rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                >
                    Connetti
                </button>
            </div>
        </>
        : <></>}</>
}

export default UndismissableStoreConnection