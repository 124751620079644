"use client";
import React, {useEffect, useState} from "react";
import Sidebar, {Notification} from "@/shared/components/Sidebar";
import Header from "@/shared/components/Header";
import {toast, Toaster} from "react-hot-toast";
import {createClient} from "@/shared/supabase/client";
import UserContext from "./UserContext";
import sidebarItems from "@/SidebarItems";
import TermsAndConditions from "@/components/modals/TermsAndConditions";
import UndismissableStoreConnection from "@/components/modals/UndismissableStoreConnection";
import RegistrationModal from "@/components/modals/RegistrationProcess";

export default function DefaultLayoutClient({
                                                children,
                                            }: {
    children: React.ReactNode;
}) {
    const supabase = createClient();
    const [permissions, setPermissions] = useState<string[]>([]);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [user, setUser] = useState<any>({});
    const [user_internal, setUser_internal] = useState<any>({});
    useEffect(() => {
        console.log('bho')
        const supabase = createClient();
        supabase.auth.getUser().then(({data:{user:user}, error}: any) => {
            console.log("asdafsasdf",user, error)
            setUser(user)
            supabase.from("users")
                .select('*, stores(*), user_plans(*,plans(*)), payment_method(*), billing(*), store_analisys(*)')
                .eq('user_id', user?.id)
                .single()
                .then(({data:user_internal, error}: any) => {
                    console.log(error)
                    setUser_internal(user_internal)
                    setPermissions(user_internal?.permissions)
                })
        })
        // supabase.from('plans').select().then((res: any) => setPlans(res))
    }, []);

    const subscribeToNotifications = () => {
        const channel = supabase
            .channel("schema-db-changes")
            .on(
                "postgres_changes",
                {
                    event: "INSERT",
                    schema: "public",
                    table: "notifications",
                    filter: `user_id=eq.${user_internal?.id}`,
                },
                (payload) => {
                    const newNotification = payload.new as Notification;
                    toast.success(newNotification.payload.message);
                    setNotifications((prev) => [newNotification, ...prev]);
                }
            )
            .on(
                "postgres_changes",
                {
                    event: "UPDATE",
                    schema: "public",
                    table: "notifications",
                    filter: `user_id=eq.${user_internal?.id}`,
                },
                (payload) => {
                    const updatedNotification = payload.new as Notification;
                    setNotifications((prev) =>
                        prev.map((notif) =>
                            notif.id === updatedNotification.id ? updatedNotification : notif
                        )
                    );
                }
            )
            .subscribe((status) => {
                // toast.success(`Realtime subscription status: ${status}`);
                if (status === "CLOSED" || status === "TIMED_OUT") {
                    if (reconnectAttempts === 0) {
                        handleReconnection(channel);
                    }
                }
            });

        return channel;
    };

    const handleReconnection = (channel: ReturnType<typeof supabase.channel>) => {
        setReconnectAttempts((prev) => prev + 1);

        // Unsubscribe and try to reconnect
        setTimeout(() => {
            if (reconnectAttempts < 5) {
                // subscribeToNotifications();
            } else {
                toast.error("Failed to reconnect after multiple attempts.");
            }
        }, 3000); // Retry every 3 seconds
    };

    useEffect(() => {
        const channel = subscribeToNotifications();

        return () => {
            supabase.removeChannel(channel);
        };
    }, [supabase]);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarTutorialOpen, setSidebarTutorialOpen] = useState(false);
    const toggleSideBarOpen = () => {
        setSidebarTutorialOpen(!sidebarTutorialOpen)
    }


    return (
        <>
            <UserContext.Provider value={{user, user_internal}}>
                {/* <!-- ===== Page Wrapper Start ===== --> */}
                <div className="flex h-screen overflow-hidden">
                    {/* <!-- ===== Sidebar Start ===== --> */}
                    <Sidebar sidebarItems={sidebarItems} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}
                             permissions={permissions} notifications={notifications} image={''}/>
                    {/* <!-- ===== Sidebar End ===== --> */}

                    {/* <!-- ===== Content Area Start ===== --> */}
                    <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                        {/* <!-- ===== Header Start ===== --> */}
                        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} image={''}
                                setSidebarTutorialOpen={toggleSideBarOpen} notifications={notifications} user={user}/>
                        {/*{process.env.NODE_ENV === 'production' ? <SidebarTutorial sidebarOpen={sidebarTutorialOpen}*/}
                        {/*                                                          setSidebarOpen={setSidebarTutorialOpen}/> : ""}*/}
                        {/* <!-- ===== Header End ===== --> */}
                        <Toaster position="bottom-right" toastOptions={{duration: 10000}}/>
                        {/* <!-- ===== Main Content Start ===== --> */}
                        <main>
                            <div className="h-full mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                                <TermsAndConditions active={false}/>
                                <UndismissableStoreConnection active={false}/>
                                <RegistrationModal plans={[] as any}/>
                                {children}
                            </div>
                        </main>
                        {/* <!-- ===== Main Content End ===== --> */}
                    </div>
                    {/* <!-- ===== Content Area End ===== --> */}
                </div>
                {/* <!-- ===== Page Wrapper End ===== --> */}
            </UserContext.Provider>
        </>
    );
}
