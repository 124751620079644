"use client";

import React, {useContext, useEffect, useRef, useState} from "react";
import {usePathname} from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import {createClient} from "@/shared/supabase/client";
import {Lock} from 'lucide-react'
import UserContext from "@/shared/components/Layouts/UserContext";

interface SidebarProps {
    sidebarItems: any[];
    sidebarOpen: boolean;
    setSidebarOpen: (arg: boolean) => void;
    permissions: any
    notifications: Notification[],
    image: string;
}

export type Notification = {
    id: number;
    created_at: number;
    type: string;
    viewed: boolean;
    module: string;
    payload: any;
}

const Sidebar = ({sidebarItems, sidebarOpen, setSidebarOpen, permissions, notifications, image}: SidebarProps) => {
    const {user, user_internal} = useContext(UserContext)
    const supabase = createClient();
    const pathname = usePathname();
    const trigger = useRef<any>(null);
    const sidebar = useRef<any>(null);

    let storedSidebarExpanded = "true";

    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
    );

    useEffect(() => {
        const clickHandler = ({target}: MouseEvent) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    }, []);

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({keyCode}: KeyboardEvent) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    useEffect(() => {
        localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector("body")?.classList.add("sidebar-expanded");
        } else {
            document.querySelector("body")?.classList.remove("sidebar-expanded");
        }
    }, [sidebarExpanded]);

    return (
        <aside
            ref={sidebar}
            className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-primary  duration-300 ease-linear dark:bg-primary lg:static lg:translate-x-0 ${
                sidebarOpen ? "translate-x-0" : "-translate-x-full"
            }`}
        >
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="flex items-center justify-between gap-2 px-6 pt-5.5 pb-6 lg:pt-6">
                <Link href="/">
                    <Image
                        className="dark:block"
                        src={"/images/Logo.png"}
                        alt="Logo"
                        width={0}
                        height={0}
                        sizes="100vw"
                        style={{
                            width: '100%',
                            maxWidth: '120px', // imposta la larghezza massima desiderata
                            height: 'auto'
                        }}
                    />
                </Link>

                <button
                    ref={trigger}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                    <svg
                        className="fill-current"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                            fill=""
                        />
                    </svg>
                </button>
            </div>
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                {/* <!-- Sidebar Menu --> */}
                <nav className="mt-4 px-4 py-4 lg:mt-9 lg:px-6">
                    {/* <!-- Menu Group --> */}
                    <div>
                        <ul className="mb-6 flex flex-col gap-1.5 text-black">
                            {sidebarItems.filter((x:any) => x.roles.includes(user_internal.role)).map((x: any) =>
                                x.public || permissions?.includes(x.name) ?
                                    <div key={x.id} >
                                        <div>{x.child}</div>
                                    </div>
                                    :  <div key={x.id} style={{pointerEvents:'none'}}>
                                    {/*TODO add lock and tooltip*/}
                                        <div>{x.child}</div>
                                    </div>
                            )}
                        </ul>
                    </div>
                    {/* <!-- Menu Item Calendar --> */}
                </nav>
                {/* <!-- Sidebar Menu --> */}
            </div>
        </aside>
    );
};

export default Sidebar;
