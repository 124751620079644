import React, {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {toast} from "react-toastify";
import axios from "@/utils/axios/axios";
import {createClient} from "@/shared/supabase/client";
import {useRouter} from "next/navigation";
import UserContext from "@/shared/components/Layouts/UserContext";

interface BillingAddressProps {
    data: any
}

const BillingAddress: React.FC<BillingAddressProps> = ({data}) => {
    console.log(data)
    const [vat, setVat] = useState<string>(data.vatNumber ?? '');
    const {user, user_internal} = React.useContext(UserContext);
    const router = useRouter();
    const {getValues, control, setValue, watch} = useForm<any>({
        defaultValues: data,
    });

    const fetchBillingInfo = async () => {
        try {
            const res = await axios.get(`/auth/billing-info/${vat}`);
            console.log(res.data)
            const data = res.data.billingInfo.data;
            setValue("companyName", data.companyName);
            setValue("address.addressline1", data.address.registeredOffice.streetName);
            setValue("address.city", data.address.registeredOffice.town);
            setValue("address.postCode", data.address.registeredOffice.zipCode);
            setValue("address.province", data.address.registeredOffice.province);
            setValue("vat", vat);  // You can set VAT as well
            setValue("sdi", data.sdiCode);
            setValue("pec", data.pec);
            setValue("address.country", data.address.registeredOffice.country ?? 'IT');
            setValue("billing_info", data)
        } catch (error) {
            console.log(error);
            toast.error("IVA not found");
        }
    };

    const save = async () => {
        const supabase = createClient();
        const billing = getValues()
        let {data: billing_info, error: errorBilling} = await supabase.from('billing_info').select().eq('vat', billing.vat ?? billing.vatNumber).single()
        console.log(billing_info, errorBilling)
        if (!billing_info) {
            const {data: new_billing_info} = await supabase.from('billing_info').upsert({
                vat: billing.vat,
                data: billing.billing_info
            })
            billing_info = new_billing_info
        }
        //Create billing
        const {data: billingSaved, error} = await supabase.from('billing').insert({
            billing_info_id: billing_info.id,
            // qonto_id: user.qonto?.id,
            data: {
                companyName: billing?.companyName,
                sdi: billing?.sdi,
                pec: billing?.pec,
                vatNumber: billing?.vat ?? billing.vatNumber,
                address: {
                    addressline1: billing?.address.addressline1,
                    city: billing?.address.city,
                    postCode: billing?.address.postCode,
                    country: billing?.address.country,
                    province: billing?.address.province,
                },
                tax: {
                    amount: 22,
                    exemption: 0
                }
            }
        }).select().single()
        console.log(billingSaved,error)
        await supabase.from('users')
            .update({billing_id: billingSaved.id})
            .eq('id', user_internal.id)
        router.refresh()
        // toast.success('Account creato')
    }
    return (
        <form>
            <div className="p-6.5 mb-5 flex flex-col gap-6 xl:flex-row" style={{overflow: 'auto'}}>
                <div className="w-full xl:w-1/2">
                    <label>Partita IVA</label>
                    <div className="flex items-center">
                        <input
                            type="text"
                            value={vat}
                            onChange={(e) => setVat(e.target.value)}
                            placeholder="Partita IVA - Es. IT13055980018"
                            minLength={6}
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                        <button
                            type="button"
                            onClick={fetchBillingInfo}
                            disabled={vat.length !== 13}
                            className="rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                        >
                            Cerca
                        </button>
                    </div>
                </div>
            </div>
            <div className="p-6.5 mb-5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full xl:w-1/2">
                    <label>Nome Azienda *</label>
                    <Controller
                        control={control}
                        name="companyName"
                        render={({field}) => (
                            <input
                                {...field}
                                placeholder="Nome Azienda"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>

                <div className="w-full xl:w-1/2">
                    <label>Indirizzo *</label>
                    <Controller
                        control={control}
                        name="address.addressline1"
                        render={({field}) => (
                            <input
                                {...field}
                                placeholder="Indirizzo"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>

                <div className="w-full xl:w-1/2">
                    <label>Città *</label>
                    <Controller
                        control={control}
                        name="address.city"
                        render={({field}) => (
                            <input
                                {...field}
                                placeholder="Città"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="p-6.5 mb-5 flex flex-col gap-6 xl:flex-row">

                <div className="w-full xl:w-1/2">
                    <label>Postcode (CAP) *</label>
                    <Controller
                        control={control}
                        name="address.postCode"
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="Postcode"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>

                <div className="w-full xl:w-1/2">
                    <label>Provincia *</label>
                    <Controller
                        control={control}
                        name="address.province"
                        render={({field}) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="Provincia"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>

                <div className="w-full xl:w-1/2">
                    <label>Nazione *</label>
                    <Controller
                        control={control}
                        name="address.country"
                        render={({field}) => (
                            <input
                                {...field}
                                required
                                placeholder="Nazione"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="p-6.5 mb-5 flex flex-col gap-6 xl:flex-row">

                {/*<div className="w-full xl:w-1/2">*/}
                {/*    <label>Partita IVA *</label>*/}
                {/*    <Controller*/}
                {/*        control={control}*/}
                {/*        name="vatId"*/}
                {/*        render={({field}) => (*/}
                {/*            <input*/}
                {/*                {...field}*/}
                {/*                required*/}
                {/*                placeholder="Partita IVA"*/}
                {/*                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="w-full xl:w-1/2">
                    <label>Codice Univoco (SDI)*</label>
                    <Controller
                        control={control}
                        name="sdi"
                        render={({field}) => (
                            <input
                                {...field}
                                required
                                placeholder="Codice Univoco (SDI)"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>

                <div className="w-full xl:w-1/2">
                    <label>PEC*</label>
                    <Controller
                        control={control}
                        name="pec"
                        render={({field}) => (
                            <input
                                {...field}
                                required
                                placeholder="PEC"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="w-full px-3 2xsm:w-1/2 items-center">
                <button
                    type="button"
                    onClick={save ?? (() => console.log("Submit action not defined"))}
                    className="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                >
                    Salva
                </button>
            </div>
        </form>
    );
};

export default BillingAddress;
