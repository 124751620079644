"use client";
import React, { ReactNode, useState } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";

interface SidebarLinkGroupProps {
  path: string;
  items: any[];
  title: ReactNode;
}

const SidebarLinkGroup = ({ path, items, title }: SidebarLinkGroupProps) => {
  const pathname = usePathname();
  const [open, setOpen] = useState<boolean>(
    pathname === path || pathname.includes(path),
  );

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <li>
      <React.Fragment>
        <Link
          href="#"
          className={`group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium hover:bg-[#83d7cf] text-white hover:text-graydark duration-300 ease-in-out ${
            (pathname === path || pathname.includes(path)) &&
            "bg-[#83d7cf]"
          }`}
          onClick={(e) => {
            e.preventDefault();
            // sidebarExpanded
            //     ?
            handleClick();
            // : setSidebarExpanded(true);
          }}
        >
          {title}
          {/*{notifications.filter(x => ['Leads', 'Quotations', 'Quotations_Final'].includes(x.module) && !x.viewed).length ?*/}
          {/*    <button*/}
          {/*        className="inline-flex rounded-full bg-[#DC3545] px-3 py-1 text-sm font-medium text-white hover:bg-opacity-90">*/}
          {/*        {notifications.filter(x => ['Leads', 'Quotations', 'Quotations_Final'].includes(x.module) && !x.viewed).length}*/}
          {/*    </button>*/}
          {/*    : ""}*/}
          <svg
            className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
              open && "rotate-180"
            }`}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
              fill=""
            />
          </svg>
        </Link>
        {/* <!-- Dropdown Menu Start --> */}
        <div
          className={`translate transform overflow-hidden ${!open && "hidden"}`}
        >
          <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
            {items.map((x: any) => (
              <li>
                <Link
                  href={x.link}
                  className={`group relative flex items-center text-white gap-2.5 rounded-md px-4 font-medium duration-300 ease-in-out hover:text-white ${pathname === x.link ? 'text-white' : ''}`}
                  onClick={() => {
                    // const notViewedNotifications = notifications.filter(x => x.module === 'Tasks' && !x.viewed)
                    // notViewedNotifications.forEach(n => markNotificationAsViewed(n.id))
                  }}
                >
                  {x.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* <!-- Dropdown Menu End --> */}
      </React.Fragment>
    </li>
  );
};

export default SidebarLinkGroup;
