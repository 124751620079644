'use client'
import {toast} from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {createClient} from "@/shared/supabase/client";
import UserContext from "@/shared/components/Layouts/UserContext";
import {useRouter} from "next/navigation";

const StoreAnalisys = () => {
    const supabase = createClient();
    const {user, user_internal} = React.useContext(UserContext);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [data, setData] = useState<any>()
    const router = useRouter();
    // console.log(user_internal?.id)
    const subscribeToNotifications = () => {
        const channel = supabase
            .channel("schema-db-changes")
            .on(
                "postgres_changes",
                {
                    event: "INSERT",
                    schema: "public",
                    table: "store_analisys",
                    filter: `user_id=eq.${user_internal?.id}`,
                },
                (payload) => {
                    setData(payload.new.data)
                    router.refresh()
                }
            )
            .subscribe((status) => {
                console.log(`Realtime subscription status: ${status}`);
                if (status === "CLOSED" || status === "TIMED_OUT") {
                    if (reconnectAttempts === 0) {
                        handleReconnection(channel);
                    }
                }
            });

        return channel;
    };
    const handleReconnection = (channel: ReturnType<typeof supabase.channel>) => {
        setReconnectAttempts((prev) => prev + 1);

        // Unsubscribe and try to reconnect
        setTimeout(() => {
            if (reconnectAttempts < 5) {
                // subscribeToNotifications();
            } else {
                toast.error("Failed to reconnect after multiple attempts.");
            }
        }, 3000); // Retry every 3 seconds
    };

    useEffect(() => {
        const channel = subscribeToNotifications();
        supabase.from("store_analisys")
            .select()
            .eq('user_id', user_internal?.id)
            .order('id', {ascending: false})
            .limit(1)
            .single().then((x) => {
            if (x) {
                setData(x)
                router.push('/dashboard');
            }
        })

        return () => {
            supabase.removeChannel(channel);
        };
    }, [supabase]);

    return <>
        <>
            <p>Attendi qualche minuto mentre recuperiamo i tuoi dati!</p>
            <div className="p-6.5 flex items-center justify-center bg-white dark:bg-black">
                <div
                    className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
            </div>
        </>
    </>
}

export default StoreAnalisys