"use client";
import React, {ReactNode, useState} from "react";
import {usePathname} from "next/navigation";
import Link from "next/link";

interface SidebarLinkGroupProps {
    path: string;
    title: ReactNode
}

const SidebarLink = ({
    path,
    title
}: SidebarLinkGroupProps) => {
    const pathname = usePathname();
    

    return <li>
            <Link
                href={`/${path}`}
                className={`group relative flex items-center gap-2.5 rounded-md px-4  py-2 font-medium hover:bg-[#83d7cf] hover:text-graydark duration-300 ease-in-out ${
                    (pathname === path || pathname.includes(path)) ?
                    "bg-[#83d7cf] " : 'text-white'
                }`}
            >
                {title}
            </Link>
    </li>;
};

export default SidebarLink;
