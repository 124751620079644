import React, {useState, useEffect, useRef, ReactNode} from "react";

interface ModalProps {
    open: boolean
    children: ReactNode;
}

const UndismissableModalController: React.FC<ModalProps> = ({open, children}) => {
    const modal = useRef<HTMLDivElement | null>(null);

    return (
        <div>
            {open && (
                <div
                    className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
                    <div
                        ref={modal}
                        className="w-full max-w-242.5 max-h-142.5 rounded-lg bg-white px-2 py-12 text-center dark:bg-boxdark md:px-17.5 md:py-15"
                        style={{overflow: 'scroll'}}
                    >
                        <div className={'gap-4'}>
                            <>
                                {children}
                            </>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default UndismissableModalController;
