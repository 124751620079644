import SidebarLink from "@/shared/components/Sidebar/SidebarLink";
import SidebarLinkGroup from "@/shared/components/Sidebar/SidebarLinkGroup";
import {
    Banknote, Book,
    BookA,
    BookText,
    Building2,
    CalendarDays,
    LayoutDashboard,
    PcCase,
    SquareChartGantt,
    SquareCheckBig, SquareKanban, Store, User,
    UserRound
} from "lucide-react";
import React from "react";

const sidebarItems = [
    {
        id: 1,
        name: "Dashboard",
        path: 'dashboard',
        public: true,
        roles: ["Admin","User"],
        child: <SidebarLink
                title={<>
                    <LayoutDashboard className="size-5" />
                    Dashboard
                    </>
                }
                path={'dashboard'}
        />
    },
    {
        id: 2,
        name: "Cases",
        path: 'cases',
        roles: ["Admin","User"],
        child: <SidebarLinkGroup path={'cases'} items={[
            {
                link: "/cases",
                text: "Cases"
            },
            {
                link: "/cases/new",
                text: "New Cases"
            },
        ]
        } 
        title={<>
            <BookText className="size-5" />
            Cases</>}>
        </SidebarLinkGroup>
    },
    {
        id: 3,
        name: "Manage New Cases",
        path: 'sales',
        roles: ["Admin"],
        child: <SidebarLink title={<>
            <Building2 className="size-5" />
            Manage New Cases
        </>} path={'manage-new-cases'}/>
    },
    {
        id: 4,
        name: "Lost Inbound",
        path: 'lost',
        roles: ["Admin"],
        child: <SidebarLink title={<>
            <PcCase className="size-5" />
            Lost Inbound
        </>} path={'lost-inbound'}/>
    },
    {
        id: 5,
        name: "Inventory",
        path: 'inventory',
        roles: ["User"],
        child: <SidebarLink title={<>
            <SquareChartGantt className="size-5" />
            Inventory
        </>} path={'inventory'}/>
    },
    {
        id: 6,
        name: "Orders",
        path: 'lost',
        roles: ["User"],
        child: <SidebarLink title={<>
            <BookA className="size-5" />
            Orders
        </>} path={'orders'}/>
    },
    {

        id: 7,
        name: "Users",
        roles: ["Admin"],
        child: <SidebarLink title={<>
            <User className="size-5" />
            Users
        </>} path={'users'}/>
    },
    {

        id: 8,
        name: "Reimbursements",
        roles: ["Admin"],
        child: <SidebarLink title={<>
            <Banknote className="size-5" />
            Reimbursements
        </>} path={'reimbursements'}/>
    },
    {

        id: 9,
        name: "Stores",
        roles: ["Admin","User"],
        child: <SidebarLink title={<>
            <Store className="size-5" />
            Stores
        </>} path={'stores'}/>
    },
    {

        id: 10,
        name: "Invoices",
        roles: ["Admin","User"],
        child: <SidebarLink title={<>
            <Book className="size-5" />
            Invoices
        </>} path={'invoices'}/>
    },
    // {
    //
    //     id: 11,
    //     name: "Tasks",
    //     roles: ["Admin"],
    //     child: <SidebarLink title={<>
    //         <SquareKanban className="size-5" />
    //         Tasks
    //     </>} path={'tasks'}/>
    // }
]
export default sidebarItems