'use client'
import React, {useContext} from "react";
import UserContext from "@/shared/components/Layouts/UserContext";
import {createClient} from "@/shared/supabase/client";
import UndismissableModal from "@/shared/components/Modals/UndismissableModal";
import toast from "react-hot-toast";
import {useRouter} from "next/navigation";

const TermsAndConditions = ({active}: { active: boolean }) => {
    const currentTecVersion = process.env.NEXT_PUBLIC_TC_VERSION
    const {user, user_internal} = useContext(UserContext)
    const router = useRouter();

    const accept = async () => {
        const supabase = createClient()
        const {data, error} = await supabase.from('users')
            .update({
                terms_conditions_approval: {
                    ...user_internal.terms_conditions_approval ?? [],
                    latestApproved: true,
                    lastVersion: currentTecVersion,
                    approvals: user_internal.terms_conditions_approval?.approvals ? [ ...user_internal.terms_conditions_approval?.approvals ,{
                        approvalDate: new Date(),
                        version: currentTecVersion
                    }] : [{
                        approvalDate: new Date(),
                        version: currentTecVersion
                    }]
                }
            })
            .eq('id', user_internal.id).select()
        if (!error) {
            // console.log(user_internal.id, data)
            toast.success('Success')
            window.location.reload()
        } else {
            console.log(error)
            toast.error(`Error: ${error}`)
        }
    }
    return <>{active ? <>
        <div className="-mx-3 flex flex-wrap gap-y-4">
            <object
                data="https://www.sosfba.it/Termini%20e%20Condizioni%20Sosfba.it%20-%20REV.%2013.12.24.docx.pdf"
                type="application/pdf"
                style={{width: '100%', height: '50vh'}}
            >
                <p>
                    Link{' '}
                    <a href="https://www.sosfba.it/Termini%20e%20Condizioni%20Sosfba.it%20-%20REV.%2013.12.24.docx.pdf">
                        to the PDF!
                    </a>
                </p>
            </object>
            <div className="w-full px-3 2xsm:w-1/2 items-center">
                <button
                    type="button"
                    onClick={accept ?? (() => console.log("Submit action not defined"))}
                    className="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                >
                    Accetta
                </button>
            </div>
        </div>
    </> : <></>}</>
}

export default TermsAndConditions