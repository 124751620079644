'use client'
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useRouter} from "next/navigation";
import {createClient} from "@/shared/supabase/client";
import UserContext from "@/shared/components/Layouts/UserContext";
import UndismissableModalControlled from "@/shared/components/Modals/UndismissableModalControlled";
import UndismissableStoreConnection from "@/components/modals/UndismissableStoreConnection";
import BillingAddress from "@/app/billing/BillingDetailsForm";
import TermsAndConditions from "@/components/modals/TermsAndConditions";
import StoreAnalisys from "@/components/modals/StoreAnalisys";

const RegistrationProcess = ({plans}: { plans: any[] }) => {
    const {user, user_internal} = React.useContext(UserContext);
    const router = useRouter();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [progress, setProgress] = useState(0);
    const user_plans = user_internal?.user_plans?.filter((x:any) => x.active)[0]
    const [plan, setPlan] = useState<any>(user_internal?.user_plans?.filter((x:any) => x.active)[0]?.plans ?? [])
    const [billing, setBilling] = useState<any>({})
    const getNextPaymentDate = (startDate: string, isAnnual: boolean): string => {
        const daysToAdd = isAnnual ? 365 : 30;
        const start = new Date(startDate);
        start.setDate(start.getDate() + daysToAdd);

        return start.toLocaleDateString('it-IT'); // Italian date format
    };
    // console.log(user_internal, user_internal.stores.length === 0)
    const steps = user_internal.role !== 'User' ? [] : [
        {
            enabled: user_internal.stores.length === 0,
            buttons_disabled: true,
            title: "Aggiungi il tuo store",
            content: (
                <>
                    <UndismissableStoreConnection active={true}/>
                </>
            ),
        },
        {
            enabled: !user_internal.billing,
            title: "Aggiungi dati di fatturazione",
            buttons_disabled: true,
            content: (
                <div>
                    <BillingAddress data={billing}/>
                </div>
            ),
        },
        {
            enabled: !user_internal.terms_conditions_approval?.latestApproved,
            title: "Termini e condizioni",
            buttons_disabled: true,
            content: (
                <div>
                 <TermsAndConditions active={true}/>
                </div>
            ),
        },
        {
            enabled: user_internal.store_analisys.length === 0,
            title: "Le tue statistiche",
            content: (
                <div>
                 <StoreAnalisys/>
                </div>
            ),
        },
        // {
        //     enabled: !user_internal.user_plans,
        //     title: "Scegli il tuo piano",
        //     content: (
        //         <div>
        //             <PricingTableOne selected={plan} products={plans} handleSubmit={setPlan}/>
        //         </div>
        //     ),
        // },
        // {
        //     enabled: plan?.name !== 'Basic' && !user_internal.payment_method,
        //     title: "Metodo di pagamento",
        //     content: (
        //         <div>
        //             <StripeFormWrapper billing={billing}
        //                                setCustomerId={setCustomerId}
        //                                setPayment_method_id={setPayment_method_id}/>
        //         </div>
        //     ),
        // },
        // {
        //     enabled: plan?.name !== 'Basic' && !user_plans?.active,
        //     title: "Conferma abbonamento",
        //     content: (
        //         <div>
        //             <div className={'p-6.5'}>
        //                 <PlanCard product={plan}/>
        //             </div>
        //             <div className={'p-6.5'}>
        //                 <p>Data inizio: {new Date(user_plans?.start_date).toLocaleDateString('it-IT')}</p>
        //                 <p>Prossimo pagamento: {getNextPaymentDate(user_plans?.start_date, plan?.annual)}</p>
        //             </div>
        //             <ChargeCustomer amount={plan?.price} customerId={customerId} payment_method_id={payment_method_id}
        //                             user_plan={user_plans}/>
        //         </div>
        //     ),
        // }
    ];

    const getFirstEnabledStep = () => {
        return steps.findIndex((step) => step.enabled) || 0; // Return the first enabled step or 0 if none are enabled
    };

    // Set the initial currentStep to the first enabled step
    useEffect(() => {
        const firstEnabledStep = getFirstEnabledStep();
        setCurrentStep(firstEnabledStep);
    }, [user_internal.stores]); // Depend on stores to trigger when user data is available


    const getNextEnabledStep = (current: number) => {
        let nextStep = current + 1;
        while (nextStep < steps.length && !steps[nextStep].enabled) {
            nextStep++;
        }
        return nextStep < steps.length ? nextStep : current; // Return the current step if no enabled step found
    };

    const getPrevEnabledStep = (current: number) => {
        let prevStep = current - 1;
        while (prevStep >= 0 && !steps[prevStep].enabled) {
            prevStep--;
        }
        return prevStep >= 0 ? prevStep : current; // Return the current step if no enabled step found
    };

    const handleNext = () => {
        const nextStep = getNextEnabledStep(currentStep);
        setCurrentStep(nextStep);
    };

    const handlePrev = () => {
        const prevStep = getPrevEnabledStep(currentStep);
        setCurrentStep(prevStep);
    };

    const submitRegistration = async () => {
        try {
            const supabase = createClient();
            const currentTecVersion = process.env.NEXT_PUBLIC_TC_VERSION;

            //Create billing_info
            let {data: billing_info} = await supabase.from('billing_info').select().eq('vat', billing.vat).single()
            console.log(billing_info)
            if (!billing_info) {
                const {data: new_billing_info} = await supabase.from('billing_info').upsert({
                    vat: billing.vat,
                    data: billing.billing_info
                })
                billing_info = new_billing_info
            }
            //Create billing
            const {data: billingSaved, error} = await supabase.from('billing').insert({
                billing_info_id: billing_info.id,
                // qonto_id: user.qonto?.id,
                data: {
                    companyName: billing?.companyName,
                    sdi: billing?.sdi,
                    pec: billing?.pec,
                    vatNumber: billing?.vat,
                    address: {
                        addressline1: billing?.addressline1,
                        city: billing?.city,
                        postCode: billing?.postCode,
                        country: billing?.country,
                        province: billing?.province,
                    },
                    tax: {
                        amount: 22,
                        exemption: 0
                    }
                }
            }).select().single()
            console.log(error)
            //Create plan
            if(plan) {
                const {data: userPlan} = await supabase.from('user_plans')
                    .insert({
                        user_id: user_internal.id,
                        plan_id: plan.id,
                        start_date: new Date()
                    }).select()
                //Update user
            }
            await supabase.from('users')
                .update({billing_id: billingSaved.id})
                .eq('id', user_internal.id)
            router.refresh()
            toast.success('Account creato')
        } catch (e:any) {
            console.error(e)
            toast.error(e.message)
        }
    };

    useEffect(() => {
        setProgress(currentStep / (steps.filter((x: any) => x.enabled).length - 1) * 100);
    }, [currentStep])

    return (
        <UndismissableModalControlled
            open={steps.filter(x => x.enabled).length > 0}>
            {/*<ProgressOne progress={progress}/>*/}
            {steps[currentStep] ? <div>
                <div>
                    <h1><strong>{steps[currentStep].title}</strong></h1>
                </div>
                <div className={'p-10'}>
                    <div>
                        {steps[currentStep].content}
                    </div>
                </div>
                {(steps[currentStep] as any).buttons_disabled ? <></> :
                    <div className="-mx-3 p-6.5 flex flex-wrap gap-y-4">
                        <div className="w-full px-3 2xsm:w-1/2 items-center">
                            <button
                                type="button"
                                disabled={currentStep === 0}
                                onClick={handlePrev}
                                className="block w-full rounded border border-primary bg-white p-3 text-center font-medium transition hover:bg-opacity-90"
                            >
                                Previous
                            </button>
                        </div>
                        {currentStep < steps.length - 1 && (
                            <div className="w-full px-3 2xsm:w-1/2 items-center">
                                <button
                                    type="button"
                                    onClick={handleNext}
                                    className="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                                >
                                    Next
                                </button>
                            </div>
                        )}
                        {currentStep === steps.length - 1 && (
                            <div className="w-full px-3 2xsm:w-1/2 items-center">
                                <button
                                    type="button"
                                    onClick={submitRegistration}
                                    className="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                                >
                                    Confirm
                                </button>
                            </div>
                        )}
                    </div>}
            </div> : <></>}
        </UndismissableModalControlled>
    );
};

export default RegistrationProcess;
