"use client";
import React, {useEffect, useRef, useState} from "react";

interface Option {
  id: string | number;
  label: string;
}

interface SelectProps {
    options: Option[] | any[];
    value: any,
    onChange: (value: number | string) => void;
}

const Select = ({options, value, onChange}:SelectProps) => {
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(!!value);
    const selectRef = useRef<HTMLSelectElement>(null);

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        onChange(isNaN(Number(selectedValue)) ? selectedValue : Number(selectedValue));
        setIsOptionSelected(true);
    };

    return (
        <div className="mb-5.5">

            <div className="relative z-20 bg-transparent dark:bg-form-input">
                <select
                    ref={selectRef}
                    value={value && value !== '' ? value : 0}
                    onChange={handleSelectChange}
                    className={`relative z-20 w-full rounded-md border border-stroke px-4 py-3 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                        isOptionSelected ? "text-bark dark:text-white" : "text-gray-400"
                    }`}
                >
                    <option disabled value={0} selected={!!value} className="text-body dark:text-bodydark">
                        Seleziona...
                    </option>
                    {options?.map((option,key) =>
                        <option key={key} value={option.id} className="text-body dark:text-bodydark">
                            {option.label}
                    </option>)}
                </select>
            </div>
        </div>
    );
};

export default Select;
